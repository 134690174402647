import React from 'react';
import PropTypes from 'prop-types';
import './confirm-delete.less';
export const ConfirmDelete = props => (
  <div className="confirm confirm-delete">
    <p className="confirm-text">确认删除？</p>
    <div className="confirm-btn">
      <button className="btn btn-primary" onClick={props.onConfirm}>删除</button>
      <button className="btn" onClick={props.onCancel}>取消</button>
    </div>
  </div>
);

ConfirmDelete.propTypes = {
  /**
     * 取消
     */
  onCancel: PropTypes.func,
  /**
     * 确认
     */
  onConfirm: PropTypes.func,
};
