/* eslint complexity: ["error", 22]*/
import React from 'react';
import {
  ChevronRightIcon,
  DiscountIcon,
  BrowseIcon,
  BrowseOffIcon,
  BacktopIcon,
  StarIcon,
  UserClearIcon,
  DeleteIcon,
  ChatIcon,
  FlagIcon,
} from 'tdesign-icons-react';
import PropTypes from 'prop-types';
import { clipboardText } from '@tencent/tc-clipboard';
import {
  TcIconSetTop,
  TcIconSetHide,
  TcIconSetShow,
  TcIconBlackName,
  TcIconAllowReply,
  TcIconStopReply,
  TcIconDelete,
  TcIconReport,
  TcIconShare,
  TcIconGoodQuestion,
  TcIconTagStrokes,
  TcIconChatBanLine,
} from 'components/IconSystem';
import { TagChoose } from '../tag-choose';
import './menu.less';
// 菜单类型
export const TYPE = {
  /** 加入黑名单 */
  BLOCK: 'BLOCK',
  /** 删除 */
  DELETE: 'DELETE',
  /** 置顶 */
  TOP: 'TOP',
  /** 举报 */
  REPORT: 'REPORT',
  /** 举报 */
  TAG: 'TAG',
};
export const Menu = (props) => {
  const changePostsList = (e, i, isTop) => {
    props.changePostsList(e, i, isTop);
  };
  if (props.is_admin) {
    return (
      <div className="menu menu-content">
        <ul>
          {!props.is_reply && (
            <div
              className="menu-item"
              role="button"
              tabIndex={0}
              onClick={(event) =>
                props.onClick({
                  opt: 'hide',
                  val: props.is_hidden ? 'off' : 'on',
                })
              }
              onKeyPress={(event) =>
                props.onClick({
                  opt: 'hide',
                  val: props.is_hidden ? 'off' : 'on',
                })
              }
            >
              {props.is_hidden ? <BrowseIcon size={17} /> : <BrowseOffIcon size={17} />}
              <span className="font">{props.is_hidden ? '取消隐藏' : '隐藏'}</span>
            </div>
          )}

          {!props.is_reply && (
            <div
              className="menu-item"
              role="button"
              tabIndex={0}
              onClick={(event) => props.onClick({ opt: 'top', val: props.is_top ? 'off' : 'on' })}
              onKeyPress={(event) => props.onClick({ opt: 'top', val: props.is_top ? 'off' : 'on' })}
            >
              <BacktopIcon size={17} />
              <span className="font">{props.is_top ? '取消置顶' : '置顶'}</span>
            </div>
          )}

          {!props.is_tag && !props.hideList.includes(TYPE.TAG) && (
            <div className="menu-item tag-menu-item">
              <DiscountIcon size={17} className="icon-tagStrokes" />
              <span className="font">
                {'标签'} <ChevronRightIcon size={17} />
              </span>
              <div className="tag-div" style={{ left: props.is_Right ? '155px' : '-140px' }}>
                <TagChoose
                  width={140}
                  isTop={props.is_top}
                  contentId={props.id}
                  productId={props.productId}
                  tagArrList={props.tagArrList}
                  changePostsList={changePostsList}
                />
              </div>
            </div>
          )}

          {!props.is_reply && (
            <div
              className="menu-item"
              role="button"
              tabIndex={0}
              onClick={(event) =>
                props.onClick({
                  opt: 'good',
                  val: props.is_good ? 'off' : 'on',
                })
              }
              onKeyPress={(event) =>
                props.onClick({
                  opt: 'good',
                  val: props.is_good ? 'off' : 'on',
                })
              }
            >
              <StarIcon size={17} />
              <span className="font">{props.is_good ? '取消好问题' : '标为好问题'}</span>
            </div>
          )}

          {!props.is_reply && <hr className="dividing-line" />}

          {!props.is_reply && (
            <div
              className="menu-item"
              role="button"
              tabIndex={0}
              onClick={(event) =>
                props.onClick({
                  opt: 'lock',
                  val: props.is_locked ? 'off' : 'on',
                })
              }
              onKeyPress={(event) =>
                props.onClick({
                  opt: 'lock',
                  val: props.is_locked ? 'off' : 'on',
                })
              }
            >
              {props.is_locked ? <ChatIcon size={17} /> : <TcIconChatBanLine />}
              <span className="font">{props.is_locked ? '允许回复' : '关闭回复'}</span>
            </div>
          )}

          {!props.is_admin_post && !props.hideList.includes(TYPE.BLOCK) && (
            <div
              className="menu-item"
              role="button"
              tabIndex={0}
              onClick={(event) =>
                props.onClick({
                  opt: 'block',
                  val: props.is_spam ? 'off' : 'on',
                })
              }
              onKeyPress={(event) =>
                props.onClick({
                  opt: 'block',
                  val: props.is_spam ? 'off' : 'on',
                })
              }
            >
              <UserClearIcon size={17} />
              <span className="font">{props.is_spam ? '取消黑名单' : '加入黑名单'}</span>
            </div>
          )}

          <div
            className="menu-item"
            role="button"
            tabIndex={0}
            onClick={(event) => props.onClick({ opt: 'delete' })}
            onKeyPress={(event) => props.onClick({ opt: 'delete' })}
          >
            <DeleteIcon size={17} />
            <span className="font">{'删除'}</span>
          </div>
        </ul>
      </div>
    );
  }

  if (props.is_owner && !props.hideList.includes(TYPE.DELETE)) {
    return (
      <div className="menu menu-content">
        <ul>
          <div
            className="menu-item"
            role="button"
            tabIndex={0}
            onClick={(event) => props.onClick({ opt: 'delete' })}
            onKeyPress={(event) => props.onClick({ opt: 'delete' })}
          >
            <DeleteIcon size={17} />
            <span className="font">{'删除'}</span>
          </div>
        </ul>
      </div>
    );
  }
  if (!props.hideList.includes(TYPE.REPORT)) {
    return (
      <div className="menu menu-content">
        <ul>
          <div
            className="menu-item"
            role="button"
            tabIndex={0}
            onClick={(event) => props.onClick({ opt: 'report' })}
            onKeyPress={(event) => props.onClick({ opt: 'report' })}
          >
            <FlagIcon />
            <span className="font">{'举报'}</span>
          </div>
        </ul>
      </div>
    );
  }
  return null;
};

Menu.propTypes = {
  has_admin_reply: PropTypes.bool,
  is_abuse: PropTypes.bool,
  id: PropTypes.string,
  productId: PropTypes.number,
  is_Right: PropTypes.bool,
  is_tag: PropTypes.bool,
  is_top: PropTypes.bool,
  is_spam: PropTypes.bool,
  is_hidden: PropTypes.bool,
  is_locked: PropTypes.bool,
  is_admin: PropTypes.bool,
  is_owner: PropTypes.bool,
  is_reply: PropTypes.bool,
  is_good: PropTypes.bool,
  is_admin_post: PropTypes.bool,
  onClick: PropTypes.func,
  handleCopyLink: PropTypes.func,
  hideList: PropTypes.array,
  tagArrList: PropTypes.array,
  changePostsList: PropTypes.func,
};

Menu.defaultProps = {
  is_tag: false,
  is_top: false,
  is_spam: false,
  is_hidden: false,
  is_locked: false,
  is_admin: false,
  is_owner: false,
  is_reply: false,
  is_good: false,
  is_admin_post: false,
  // 隐藏操作的列表
  hideList: [],
  onClick: () => console.log('Menu onClick 未绑定'),
  handleCopyLink: () => console.log('Nenu handleCopyLink 没有'),
};
