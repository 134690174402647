
/** 小程序场景值 */
export const SCENE_CODE = {
  /** 从小程序进入 */
  FROM_WEAPP: 1037,
  /** 从小程序模板消息 */
  FROM_TEMP: 1014,
  /** 单人聊天 */
  FROM_CHAT: 1007,
  /** 群聊 */
  FROM_CHAT_GROUP: 1008,
};

/**
 * 图片上传和发帖类型是绑定的
 * 做什么类型的操作在上传图片是type需要传同一个类型
 */
export const IMAGE_UPLOAD_TYPE = {
  /** 评论 */
  REPLY: 'reply',
  /** 发帖 */
  POST: 'post',
  /** 发表i want   */
  I_WANT_IT: 'i_want_it',
  /** i want 评论  */
  I_WANT_REPLY: 'i_want_reply',
  /** 话题官方回复  */
  TOPIC_POST: 'topic_post',
};

/**
 * 产品类型 对应后台设置 大产品首页 、 轻量产品首页
 */
export const DISPLAY_MODE = {
  /** 轻量产品 */
  NORMAL: 0,
  /** 大产品 */
  LARGE: 1,
};
export const POST_TYPE = {
  /** 提缺陷 */
  BUG: 1,
  /** 提建议 */
  FEATURE: 2,
  /** 我想要 */
  I_WANT: 3,
  /** 更新日志*/
  CHANGE_LOG: 4,
  /** 自定义帖子类型 - 广告 */
  AD: 'advertisement',
};

/** 兔小巢项目ID */
export const TXC_PRODUCT_ID = 1368;
export const QQ_IOS_PRODUCT_ID = 36456;
export const QQ_ANDROID_PRODUCT_ID = 36028;
export const QQ_PRODUCT_ID = 36086;

// 微信订阅消息模板ID
export const WX_TEMPL_ID = 't-rA5PltTnbMhefKnOCxhZEtaFp57o3ePnyWCxYcFc0';
// 举报产品线上环境ID
export const TXC_INFORM_ID = 333559;
// 举报产品测试环境ID
export const TXC_INFORM_TEST_ID = 264646;

// 平台关联-CDOING 测试环境ID
export const TXC_PLATFOR_CODING_TEST_ID = 10002;
// 平台关联-CDOING 正式环境ID
export const TXC_PLATFOR_CODING_ID = 20001;
// 平台关联-TAPD 测试环境ID
export const TXC_PLATFOR_TAPD_TEST_ID = 20002;
// 平台关联-TAPD 正式环境ID
export const TXC_PLATFOR_TAPD_ID = 20002;

// actions
export const SET_CUSTOM_DATA = 'SET_CUSTOM_DATA';
export const SET_MENU = 'SET_MENU';
export const APP_ADD_OPERATION = 'APP_ADD_OPERATION';
export const APP_CLEAR_OPERATION = 'APP_CLEAR_OPERATION';
export const APP_SET_SYSTEM_INFO = 'APP_SET_SYSTEM_INFO';
export const APP_SET_PRODUCT_INFO = 'APP_SET_PRODUCT_INFO';

/** 由于合规等一系列问题需要隐藏 */
export const HIDDEN_IMAGE_SELECT_PRODUCTS = [
  '341701',
  '142735',
  '40536',
  '63488',
  '364661', // 企鹅优学 （合规）
  '75650', // QQ空间独立版安卓 webview点击 <input type=file/> 无反应
  '163282', // QQ安全中心登录 ,合规问题隐藏图片上传按钮
  '322944', // 鹅剪iOS：322944
  '324845', // 鹅剪安卓：324845
  '54296', // 腾讯云小微
];

// 话题标签类型
export const TOPICS_TAB = {
  /** (默认): 最新 */
  NEWEST: 'newest',
  /** 热门 */
  POPULAR: 'popular',
  /** 推荐 */
  RECOMMEND: 'recommend',
  /** 推荐+热门 */
  RECOMMEND_POPULAR: 'recommend_popular',
};


/** 产品配置项 */
export enum ATTRIBUTE_TYPE {
  /** 广告 */
  AD_STATUS = 'ad_status',
  /** 是否去广告 */
  AD_STATUS_OFF = 'ad_status_off',
  /** 暗黑模式 */
  DARK_MODE = 'dark_mode',
  /** 图片上传功能 */
  UPLOAD_IMG = 'upload_img',
  /** 非管理员回复渲染成链接功能 */
  RENDER_LINK = 'ugc_url_status',
  /** 产品广告位配置 */
  AD_FEED = 'ad_feed',

}
export enum ATTRIBUTE_STATUS {
  /** 开启 */
  ON = 'on',
  /** 关闭 */
  OFF = 'off',
}

export const IS_LOADING = 'IS_LOADING';

export const ROADMAP_GUIDE_URL = 'https://docs.qq.com/doc/p/7a839fbaec331f3f4256356d5e4369e9b5458308';
